import LoginApi from '@/api/login';
import { tenantBaseInfo } from '@/api/company';
import SystemSetting from '@/api/systemSetting';

export default async (id) => {
  // 切换登录到指定的企业
  if (id) {
    await LoginApi.switchCompany(id);
    const list = await LoginApi.ownerList();
    // list.body = list.body.filter(item => item.isPersonal === false);
    list.body = list.body.splice(list.body.findIndex(item => item.isPersonal), 1).concat(list.body);
    localStorage.setItem('haveCompany', list.body.length > 1 || false);
    localStorage.setItem('ownerList', JSON.stringify(list.body));

    const obj = list.body.length ? list.body.find(item => id === item.tenantId) : {};

    const userDetail = await SystemSetting.getDetailEmploy({
      findDepartmentFlag: true,
      findRoleFlag: true,
      id: obj.employeeId,
      tenantId: id,
    });
    localStorage.setItem('userInfo', JSON.stringify(userDetail.body));
    if (!userDetail.body.isPersonal) {
      localStorage.removeItem('entryStatus');
      const { body } = await tenantBaseInfo({});
      let entryStatus = {
        enteringStatus: body.enteringStatus || null,
        enteringAuditStatus: body.enteringAuditStatus || null,
        tenantServiceInfoStatus: body.tenantServiceInfoStatus || null,
        tenantServiceInfoOrderPayStatus: body.tenantServiceInfoOrderPayStatus || null,
      };
      localStorage.setItem('entryStatus', JSON.stringify(entryStatus));
    }
  }
};
